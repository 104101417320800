<template lang="pug">
include /mixins.pug

+b.filters-bar.--horizontal(
  :class="{ 'is-sticky': isDesktop }"
)
  +b.preloader--full-block.--white(v-if='isLoad')
  +b.g-container.--fit_lg-sm.--fit_mac-xl.relative
    +b.filters-bar__reset-btn(@click.prevent='resetFilters')
      +b.SPAN.ds-caption.--color_inherit.--size_sm.--regular {{ $t('reset') }}

    +b.g-row.--appearance_spaced.--align_end-till-xl.--justify(v-if="price.length")
      +b.g-cell.g-cols--12.--6-sm.--5-md.--3-xl
        RangeWidget(
          :filter-url-attr="filterUrlAttr"
          :min="price[0]"
          :max="price[1]"
          @update:range="setPriceRange"
        )
      +b.g-cell.g-cols--12.--2-xl.is-visible-xl
        DController(
          :class="'d-control-descriptor--br_dashed'"
          :component="'Multiselect'"
          :name="'nativeLanguage'"
          :input-label=`$t("Родной язык:")`
          :options="options.nativeLanguage"
          v-model="formdata.nativeLanguage"
          track-by="slug"
          :multiple="true"
          :labelBy="'label'"
          @update:model-value="updateFilter($event, 'nativeLanguage')"
        )
          template(#selection)

      +b.g-cell.g-cols--7.--6-sm.--4-md.--2-xl
        DController(
          :class="'d-control-descriptor--br_dashed'"
          :component="'Multiselect'"
          :name="'order'"
          :input-label=`$t("Сортировать по:")`
          :options="options.order"
          v-model="formdata.order"
          track-by="slug"
          :labelBy="'label'"
          @update:model-value="updateFilter($event, 'order')"
        )

      teleport(to="#teleport-wrap" :disabled="isDesktop" v-if="init")
        +b.g-cell.g-cols--12.--2-xl.ec-space_mb--2-till-xl
          DController(
            :class="'d-control-descriptor--br_dashed'"
            :component="'Multiselect'"
            :name="'studentAges'"
            :input-label=`$t("Возраст ученика:")`
            :options="options.studentAges"
            v-model="formdata.studentAges"
            track-by="slug"
            :labelBy="'label'"
            @update:model-value="updateFilter($event, 'studentAges')"
          )

        +b.g-cell.g-cols--12.--auto-xl.ec-space_mb--2-till-xl.contact_us_button
          +b.d-control-descriptor
            +b.A.link-button.--style_secondary.--br_dashed-blue.--hover_br-blue.--full-size(
              :href="whatsApp"
              target="_blank"
              rel="noopener noreferrer"
            )
              +b.ds-link.inline--between.full-w
                +b.SPAN.ds-caption.--color_main-dark.--size_sm.--regular {{ $t('Связаться с нами') }}
                +b.SPAN.svg-image.icons.--w_md.--center
                  i-whats-app-icon(class="svg-image__element")


        +b.g-cell.g-cols--12.--1-xl.search-icon
          ui-accordion(
            v-slot="{ toggleActive, activeState, collapse }"
          )
            ui-click-outside(
              :do='collapse'
              v-slot="{ ref }"
            )
              +b.d-control-descriptor(
                :ref='ref'
              )
                +b.link-button.--style_secondary.--br_dashed-blue.--hover_br-blue.--full-size.--min-full-size(@click.prevent.stop='toggleActive()')
                  +b.ds-link.inline--between
                    +b.SPAN.ds-caption.--color_main-dark.--regular
                    +b.SPAN.svg-image.icons.--w_md.--center
                      i-search(class="svg-image__element")
                transition(name='fade')
                  catalog-search(
                    v-model="formdata.search"
                    v-show="activeState"
                    :reset-trigger="resetTrigger"
                    @update:search="setSearch($event), collapse()"
                  )

      +b.g-cell.g-cols--5.--3-sm.is-disable-xl
        +b.d-control-descriptor
          +b.BUTTON.link-button.--style_secondary.--br_dashed-blue.--hover_br-blue.--full-size(@click.prevent="init = true, emit('trigger:show', true)")
            +b.SPAN.ds-caption.--color_main-dark.--size_sm.--regular.--appearance_nowrap {{ $t('Другие фильтры') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, reactive, defineEmits, watchEffect, watch, onMounted } from 'vue'
import { prepareSetData } from '@utils/filters'

const emit = defineEmits(['update:filters', 'trigger:show', 'reset:filters'])

const props = defineProps({
  filterUrlAttr: {},
  resetTrigger: Boolean,
  filterAttrs: {
    type: Object,
    default: {},
  },
  isLoad: Boolean,
})

const whatsApp = window.whatsApp || '#'
const init = ref(null)
const isDesktop = ref(null)
const price = ref([])

let formdata = reactive({
  cost_from: [],
  cost_to: [],
  order: [],
  studentAges: [],
  nativeLanguage: [],
  search: '',
})

let options = ref({
  nativeLanguage: [],
  studentAges: [],
  order: [],
})

onMounted(() => {
  checkInnerWidth()
  window.addEventListener('resize', () => {
    setTimeout(() => {
      checkInnerWidth()
    }, 300)
  })
})

const checkInnerWidth = () => {
  const xl = 1280
  if (window.innerWidth >= xl) {
    isDesktop.value = true
    init.value = true
  } else {
    isDesktop.value = false
  }
}

const setPriceRange = range => {
  formdata.cost_from = String(range[0])
  formdata.cost_to = String(range[1])
  emit('update:filters', formdata)
}

const updateFilter = (data, key) => {
  formdata[key] = data
  emit('update:filters', formdata)
}

const setSearch = quiery => {
  formdata.search = quiery
  emit('update:filters', formdata)
}

const resetFilters = () => {
  Object.keys(formdata).forEach(key => {
    formdata[key] = []
  })
  formdata.search = ''
  emit('reset:filters')
}

watch(() => props.resetTrigger, () => {
  Object.keys(formdata).forEach(key => {
    formdata[key] = []
  })
  formdata.search = ''
})

watchEffect(() => {
  if (props.filterAttrs) {
    Object.keys(props.filterAttrs).forEach(key => {
      if ('cost' !== key) {
        options.value[key] = props.filterAttrs[key].choices
      } else {
        const { min, max } = props.filterAttrs[key]
        price.value = [ min, max ]
      }
    })
  }
  if (props.filterUrlAttr.value) {
    let currentUrlAttrs = {}
    Object.keys(props.filterUrlAttr.value).map(k => {
      if (Object.keys(formdata).includes(k)) {
        return currentUrlAttrs[k] = props.filterUrlAttr.value[k]
      }
    })
    formdata = prepareSetData(currentUrlAttrs, options.value, formdata)
    if (!props.filterUrlAttr.value.nativeLanguage) {
      formdata.nativeLanguage = []
    }
  }
})

</script>
