import {
  createReceiver,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'country'

const COUNTRIES_URL = prefixAPI('/list/code/', MODEL)
export const countriesResource = createReceiver(COUNTRIES_URL)

const COUNTRIES_OPTIONS_URL = prefixAPI('/list/options/', MODEL)
export const countriesChoices = createReceiver(COUNTRIES_OPTIONS_URL)
