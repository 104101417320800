<template lang="pug">
include /mixins.pug

+b.range
  +e.input-wrapper
    +e.text
      +b.P.ds-caption.--size_sm.--color_main-dark.--regular {{ $t('Цена за час:') }}
    +e.INPUT.input(
      :class="{'is-disabled': isDisabled}"
      v-model.number="rangeValue[0]"
      @input="checkValue($event, 'min')"
      @keydown.enter.prevent=''
      v-access="'[0-9]'"
    )
    +e.decor
    +e.INPUT.input(
      :class="{'is-disabled': isDisabled}"
      v-model.number="rangeValue[1]"
      @input="checkValue($event, 'max')"
      @keydown.enter.prevent=''
      v-access="'[0-9]'"
    )
    +e.btn(
      :class="{'is-disabled': isDisabled}"
      @click.prevent="isDisabled ? '': updateVal()"
    )
      +b.P.ds-caption.--color_inherit.--size_sm.--medium {{ $t('Ok') }}

  vue-slider(
    v-model="rangeValue"
    v-bind="options"
    @change="isDisabled = false"
  )

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, defineEmits, watchEffect } from 'vue'

const props = defineProps({
  min: {},
  max: {},
  filterUrlAttr: {
    default: null,
  },
  resetTrigger: Boolean
})

const emit = defineEmits(['update:range'])

const rangeValue = ref([null, null])

const isDisabled = ref(false)

const dir = window.isRtl === 'True' ? 'rtl': 'ltr'

const options = {
  order: true,
  height: 4,
  dotSize: 16,
  tooltip: 'none',
  direction: dir,
  enableCross: false,
  min: props.min,
  max: props.max,
  clickable: false,
  dragOnClick: true,
  silent: true,
  minRange: 10,
}

watchEffect(() => {
  if (props.filterUrlAttr.value?.cost_from && props.filterUrlAttr.value?.cost_to) {
    rangeValue.value = [Number(props.filterUrlAttr.value?.cost_from), Number(props.filterUrlAttr.value?.cost_to)]
  } else {
    rangeValue.value = [props.min, props.max]
  }
})

const checkValue = ({target: { value }}, key) => {
  isDisabled.value = false
  const [minV, maxV] = rangeValue.value
  const minK = 'min' === key
  const maxK = 'max' === key
  const n = v => Number(v)
  if (minK && (n(value) < props.min || n(value) > props.max) || maxK && (n(value) > props.max || n(value) < props.min)) {
    isDisabled.value = true
  }
  if (minK && n(value) === maxV || maxK && n(value) === minV) isDisabled.value = true
  if (minK && n(value) > maxV || maxK && n(value) < minV) isDisabled.value = true

}

const updateVal = () => {
  const [minV, maxV] = rangeValue.value
  rangeValue.value = [minV <= props.min ? props.min : minV, maxV >= props.max ? props.max : maxV]
  if (minV >= maxV) rangeValue.value = [maxV, minV]
  emit('update:range', rangeValue.value)
}

</script>