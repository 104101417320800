<template lang="pug">
include /mixins.pug
+b.share
  template(v-for="network in networks")
    +e.SHARE-NETWORK.link(
      tag='div'
      :url="shareUrl"
      :network='network.network'
      :title="shareTitle"
    )
      +e.icon
        component(
          :is='network.icon'
        )

  +e.link.relative(
    v-clipboard:copy="shareUrl"
    v-clipboard:success="onCopy"
  )
    +e.icon
      i-share-link
    +e.prompt(
      v-if="copy"
    )
      +b.SPAN {{ $t('Copy') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref } from 'vue'

const props = defineProps({
  shareUrl: String,
  shareTitle: String,
})

const copy = ref(false)

const networks = [
  { network: 'facebook', icon: 'i-share-facebook' },
  { network: 'vk', icon: 'i-share-vk' },
  { network: 'twitter', icon: 'i-share-twitter' },
]

const onCopy = () => {
  copy.value = true
  setTimeout(hide, 1500)
}
const hide = () => {
  copy.value = false
}

</script>
